import Pico8 from "react-pico-8";
import { Controls, Reset, Pause, Sound, Carts, Fullscreen } from "react-pico-8/buttons";
import * as React from 'react';
export default {
  Pico8,
  Controls,
  Reset,
  Pause,
  Sound,
  Carts,
  Fullscreen,
  React
};